<template>
    <DatePicker ref="datepicker" v-model="value" class="date-picker" v-bind="options">
        <template #input-icon>
            <CalendarSvg></CalendarSvg>
        </template>
    </DatePicker>
</template>
<script setup lang="ts">
// Svg
import CalendarSvg from '@/assets/calendar.svg';

// Components
import DatePicker, { DatePickerInstance, VueDatePickerProps } from '@vuepic/vue-datepicker';

// Other
import { DateTime } from 'luxon';
import { formatDate } from '../utils/date-utils';
import { ref, defineProps, defineEmits, computed } from 'vue';
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';

// At the moment I don't want to localize the date-picker, and now there is no necessity
const defaultFormatWithQuarter = `DD (q кв. yyyy г.)`;

const { t } = useI18n();
const emit = defineEmits(['edit-request', 'input', 'update:modelValue']);
const props = defineProps({
    config: { type: Object, default: () => ({}) },
    inline: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    modelValue: { type: [String, Date, Array] as PropType<string | string[] | Date | Date[]>, default: () => null },
    placeholder: { type: String, default: '' },
});

const datepicker = ref(null as DatePickerInstance | null);
const options = computed((): VueDatePickerProps => {
    return {
        format: (dates: Date | Date[]): string => {
            if (Array.isArray(dates)) {
                return dates.reduce((carry, date) => {
                    return carry + formatDate(date, defaultFormatWithQuarter);
                }, ' — ');
            }

            return formatDate(dates, defaultFormatWithQuarter);
        },
        offset: 4,
        locale: 'ru',
        autoApply: true,
        clearable: false,
        timePickerInline: true,
        enableTimePicker: false,
        readonly: props.disabled,
        placeholder: t('default-placeholder', 1),
        startTime: {
            hours: 0,
            minutes: 0,
        },
        textInput: {
            format: 'dd.MM.yyyy',
        },
        monthNameFormat: 'long',
        position: 'left',
        ...props.config,
    };
});
const value = computed({
    get(): string | string[] | Date | Date[] {
        return props.modelValue;
    },

    set(newValue: string | string[] | Date | Date[]): void {
        function setValue(newValue: string | string[] | Date | Date[]) {
            if (props.modelValue === newValue) {
                return;
            }

            if (newValue instanceof Date) {
                newValue = options.value.enableTimePicker
                    ? DateTime.fromJSDate(newValue).toISO() ?? ''
                    : DateTime.fromJSDate(newValue).toISODate() ?? '';
            }

            if (Array.isArray(newValue)) {
                newValue = newValue.map((date): string => {
                    if (date instanceof Date) {
                        return options.value.enableTimePicker
                            ? DateTime.fromJSDate(date).toISO() ?? ''
                            : DateTime.fromJSDate(date).toISODate() ?? '';
                    }
                    return newValue as string;
                });
            }

            emit('input', newValue);
            emit('update:modelValue', newValue);
        }

        if (!props.editable) {
            datepicker.value?.closeMenu();
            emit('edit-request', { callback: () => setValue(newValue) });
            return;
        }

        setValue(newValue);
    },
});
</script>

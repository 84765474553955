import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-layout" }

import VSanta from './VSanta.vue';
import VAppContent from './VAppContent.vue';
import VAppSidebar from './VAppSidebar.vue';
import VAppSidebarMobile from './VAppSidebarMobile.vue';
import { Splitpanes as VSplitpanes, Pane as VPane } from 'splitpanes';

// Other
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { computed } from 'vue';
import { isWinter } from '@/utils/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'VAppLayout',
  setup(__props) {

// Components
const sidebarWidth = computed(Storages.Settings.computedDebounce(Settings.UI.SidebarWidth, 15));
const winterMode = computed(() => {
    if (!isWinter()) {
        return false;
    }
    return Storages.Settings.getOrDefault<boolean>(Settings.UI.WinterEnabled, true);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(VSplitpanes), {
      class: "",
      onResize: _cache[0] || (_cache[0] = ($event: any) => (sidebarWidth.value = $event[0].size))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(VPane), {
          class: "splitpanes__pane--sidebar splitpanes__pane--shadow",
          "max-size": "40",
          "min-size": "10",
          size: _ctx.$device.isMobile ? 0 : sidebarWidth.value
        }, {
          default: _withCtx(() => [
            (!_ctx.$device.isMobile && winterMode.value)
              ? (_openBlock(), _createBlock(VSanta, { key: 0 }))
              : _createCommentVNode("", true),
            _createVNode(VAppSidebar, { class: "app-layout__block app-layout__block--sidebar" }),
            _createVNode(VAppSidebarMobile)
          ]),
          _: 1
        }, 8, ["size"]),
        _createVNode(_unref(VPane), {
          size: _ctx.$device.isMobile ? 100 : 100 - sidebarWidth.value
        }, {
          default: _withCtx(() => [
            _createVNode(VAppContent, { class: "app-layout__block app-layout__block--content" }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            })
          ]),
          _: 3
        }, 8, ["size"])
      ]),
      _: 3
    })
  ]))
}
}

})
<template>
    <div class="app-layout">
        <VSplitpanes class="" @resize="sidebarWidth = $event[0].size">
            <VPane
                class="splitpanes__pane--sidebar splitpanes__pane--shadow"
                max-size="40"
                min-size="10"
                :size="$device.isMobile ? 0 : sidebarWidth"
            >
                <VSanta v-if="!$device.isMobile && winterMode"></VSanta>
                <VAppSidebar class="app-layout__block app-layout__block--sidebar"></VAppSidebar>
                <VAppSidebarMobile></VAppSidebarMobile>
            </VPane>

            <VPane :size="$device.isMobile ? 100 : 100 - sidebarWidth">
                <VAppContent class="app-layout__block app-layout__block--content">
                    <slot></slot>
                </VAppContent>
            </VPane>
        </VSplitpanes>
    </div>
</template>

<script setup lang="ts">
// Components
import VSanta from './VSanta.vue';
import VAppContent from './VAppContent.vue';
import VAppSidebar from './VAppSidebar.vue';
import VAppSidebarMobile from './VAppSidebarMobile.vue';
import { Splitpanes as VSplitpanes, Pane as VPane } from 'splitpanes';

// Other
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { computed } from 'vue';
import { isWinter } from '@/utils/utils';

const sidebarWidth = computed(Storages.Settings.computedDebounce(Settings.UI.SidebarWidth, 15));
const winterMode = computed(() => {
    if (!isWinter()) {
        return false;
    }
    return Storages.Settings.getOrDefault<boolean>(Settings.UI.WinterEnabled, true);
});
</script>

<style lang="scss">
.app-layout {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--background-color);
    --background-color: var(--background-secondary);
    overflow: hidden;

    &__block {
        height: 0.0625rem;
        min-height: 100%;

        &--content {
            overflow: hidden;
        }
    }
}
</style>

<template>
    <div class="task-panel">
        <div class="task-panel__scroll">
            <div
                class="task-panel__row task-panel__row--center task-panel__row--padding task-panel__row--secondary"
                v-if="task.archivedAt"
            >
                <NoticeSvg class="task-panel__alert-icon"></NoticeSvg>

                <h4 class="task-panel__alert">
                    {{ t('task-archived') }}
                </h4>
            </div>

            <div class="task-panel__row task-panel__row--first task-panel__row--close-button-space">
                <VTextarea
                    class="simple-textarea--h2"
                    :class="{
                        error: formErrors?.title,
                    }"
                    concurrency-mode
                    debounce-mode
                    :disabled="!canUpdate || disabled"
                    :editable="editable"
                    maxlength="512"
                    :model-value="task.title"
                    required
                    @edit-request="confirmToDraft"
                    @update:model-value="changeTitleAsync(task, $event)"
                ></VTextarea>

                <VButton
                    class="button--rounded button--transparent task-panel__button task-panel__button--close"
                    @click="$emit('close')"
                >
                    <TimesSvg></TimesSvg>
                </VButton>
            </div>

            <div class="task-panel__row task-panel__row--padding" v-if="isSubtask">
                <VInlineLink
                    class="prevent-close"
                    :subject="t('attached-to-task')"
                    :to="{
                        query: {
                            task: parent.id,
                        },
                    }"
                    v-if="parent"
                >
                    {{ parent.title ? parent.title : t('untitled') }}
                </VInlineLink>
            </div>

            <div class="task-panel__row task-panel__row--padding" v-else>
                <VInlineLink
                    class="prevent-close"
                    :subject="t('board-link-text')"
                    :to="{
                        name: 'projects.board',
                        params: {
                            projectId: project.id,
                            boardId: board.id,
                        },
                        query: {
                            task: task.id,
                        },
                    }"
                    v-if="project && board"
                >
                    {{ tryTranslate(board.title) }}, {{ tryTranslate(project.title) }}
                </VInlineLink>
            </div>

            <div class="task-panel__row task-panel__row--padding task-panel__row--separate">
                <ul class="info-panel task-panel__info-panel">
                    <li class="info-panel__row">
                        <span
                            class="task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right"
                            >{{ t('status') }}</span
                        >

                        <div class="info-panel__content info-panel__content--vcenter info-panel__content--buttons">
                            <VTaskStatus class="status--space-right" :task="task"></VTaskStatus>

                            <template v-for="(action, index) in actions" :key="index">
                                <VButton :class="action.panelClasses" @click="action.action" v-if="action.isMainAction"
                                    >{{ action.title }}
                                </VButton>
                            </template>

                            <VButtonDropdown :options="actions" placement="left-start" v-if="actions.length">
                                <DotsSvg></DotsSvg>
                            </VButtonDropdown>
                        </div>
                    </li>

                    <li class="info-panel__row">
                        <span
                            class="task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right"
                            >{{ parentTitle }}</span
                        >

                        <div class="info-panel__content info-panel__content--vcenter">
                            <VSelect
                                class="v-select--primary inline-50-x inline-fluid"
                                :disabled="!canUpdate || disabled || isSubtask"
                                label="title"
                                :model-value="task.parentId"
                                :options="parentOptions"
                                :placeholder="t('default-placeholder', 10)"
                                :reduce="getOptionValue"
                                @open="fetchParentsOptionsIfNotExistsOrStartEdit"
                                @option:selected="changeParentTaskAsync(task, $event.value)"
                                @search="fetchParentsOptions"
                            >
                                <template v-slot:selected-option="option">
                                    <VInlineLink
                                        class="inline-link--selected-option"
                                        :to="parentLink"
                                        @mousedown.stop
                                        v-if="parent && option && parentLink"
                                    >
                                        {{ parent.title ? parent.title : t('untitled') }}
                                    </VInlineLink>
                                </template>
                            </VSelect>
                        </div>
                    </li>

                    <li class="info-panel__row">
                        <span
                            class="task-panel__label info-panel__label info-panel__label--vcenter required inline-space-75-right"
                            >{{ t('deadline') }}</span
                        >

                        <div class="info-panel__content">
                            <VDatePicker
                                class="inline-50-x inline-fluid"
                                :class="{
                                    error: formErrors?.deadline,
                                }"
                                :disabled="!canUpdate || disabled"
                                :editable="editable"
                                :model-value="task.deadline"
                                @edit-request="confirmImmediatelyChange"
                                @update:model-value="changeDeadlineAsync(task, $event)"
                            ></VDatePicker>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="task-panel__row task-panel__row--padding task-panel__row--separate">
                <ul class="info-panel">
                    <li class="info-panel__row">
                        <span
                            class="task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right"
                            >{{ t('author') }}</span
                        >

                        <div
                            class="info-panel__content info-panel__content--vcenter info-panel__content--overflow-hidden"
                        >
                            <VUsersList
                                :disabled="!canUpdateAuthor || disabled"
                                :editable="canUpdateAuthor && !disabled"
                                :users="[task.author!]"
                                @open="showAuthorSelector"
                            ></VUsersList>
                        </div>
                    </li>

                    <li class="info-panel__row">
                        <span
                            class="task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right required"
                            >{{ t('assignee') }}</span
                        >

                        <div
                            class="info-panel__content info-panel__content--vcenter info-panel__content--overflow-hidden press-50-x inline-50-x"
                            :class="{
                                error: formErrors?.collaborators,
                            }"
                        >
                            <VUsersList
                                :disabled="!canManageCollaborators || disabled"
                                :editable="canManageCollaborators && editable"
                                :users="assignees"
                                @edit-request="confirmToDraft"
                                @open="showMangers"
                            ></VUsersList>
                        </div>
                    </li>

                    <li class="info-panel__row">
                        <span
                            class="task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right"
                            >{{ t('contributors') }}</span
                        >

                        <div
                            class="info-panel__content info-panel__content--vcenter info-panel__content--overflow-hidden"
                        >
                            <VUsersList
                                :disabled="!canManageCollaborators || disabled"
                                :editable="canManageCollaborators"
                                :users="contributors"
                                @open="showContributors"
                            ></VUsersList>
                        </div>
                    </li>

                    <li class="info-panel__row">
                        <span
                            class="task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right"
                            >{{ t('collaborators') }}</span
                        >

                        <div
                            class="info-panel__content info-panel__content--vcenter info-panel__content--overflow-hidden"
                        >
                            <VUsersList
                                :disabled="!canManageCollaborators || disabled"
                                :editable="canManageCollaborators"
                                :users="collaborators"
                                @open="showCollaborators"
                            ></VUsersList>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="task-panel__row task-panel__row--padding task-panel__row--separate">
                <span class="task-panel__label task-panel__label--separate required">
                    {{ t('description') }}
                </span>

                <VContentEditable
                    class="simple-contenteditable--unlimited simple-contenteditable--great inline-100-x inline-fluid"
                    :class="{
                        error: formErrors?.description,
                    }"
                    concurrency-mode
                    debounce-mode
                    :disabled="!canUpdate || disabled"
                    :editable="editable"
                    maxlength="4096"
                    :model-value="task.description"
                    @edit-request="confirmToDraft"
                    @update:model-value="changeDescriptionAsync(task, $event)"
                ></VContentEditable>
            </div>

            <div class="task-panel__row task-panel__row--padding task-panel__row--separate">
                <span class="task-panel__label task-panel__label--separate-x2">
                    {{ t('attachments') }}
                </span>

                <VFileList
                    :attachments="attachments"
                    :queue="fileQueue"
                    :uploader="uploader"
                    @removed="removeStoryAsync(task, $event)"
                    @uploaded="uploadedAttachmentHandler"
                ></VFileList>
            </div>

            <div
                class="task-panel__row task-panel__row--padding task-panel__row--separate"
                v-if="(subtasks && subtasks.length) || canCreateSubTasks"
            >
                <h3 class="task-panel__header task-panel__header--title task-panel__header--with-button">
                    {{ t('subtasks') }}

                    <VButton
                        class="button--secondary button--vcenter button--transparent task-panel__header-button"
                        @click="createSubtaskAsync(task)"
                        v-if="canCreateSubTasks"
                    >
                        <PlusSvg></PlusSvg>
                        {{ t('add-subtask') }}
                    </VButton>
                </h3>

                <div class="task-panel__subtasks">
                    <template v-for="(subtask, index) in subtasks" :key="index">
                        <RouterLink
                            :to="{
                                query: { task: subtask.id, prevTask: task.id, prevSelected: route.query.selected },
                            }"
                        >
                            <VTaskCardInline class="task-panel__subtask" inline :task="subtask"></VTaskCardInline>
                        </RouterLink>
                    </template>
                </div>
            </div>

            <div class="task-panel__row task-panel__row--padding" v-if="task.approvements">
                <h3 class="task-panel__header task-panel__header--title">{{ t('approval-list-title') }}</h3>

                <VApprovementList
                    :disabled="disabled"
                    :model-value="task.approvements"
                    @approve="approveAsync(task, $event)"
                    @disapprove="disapproveAsync(task, $event)"
                ></VApprovementList>
            </div>

            <div class="task-panel__row task-panel__row--flex task-panel__row--padding" v-if="task.stories">
                <h3 class="task-panel__header task-panel__header--title task-panel__header--selector inline-75-x">
                    <VSelect
                        v-model="storyViewMode"
                        class="v-select--primary v-select--shrunken"
                        label="title"
                        :options="viewModeOptions"
                        :placeholder="t('default-placeholder', 2)"
                        :reduce="getOptionValue"
                        :searchable="false"
                    >
                    </VSelect>
                </h3>

                <VFeedStory
                    class="task-panel__stories"
                    selectable
                    :stories="task.stories"
                    :task="task"
                    title
                    :view-mode="storyViewMode"
                    @remove-story="removeStoryAsync(task, $event)"
                    @start-edit-story="startEdit"
                ></VFeedStory>
            </div>
        </div>

        <div class="task-panel__chat" v-if="canComment">
            <VChatInput
                v-model:editModel="editComment"
                v-model:newModel="newComment"
                :uploader="uploader"
                @start-edit="startEdit"
                @stop-edit="stopEdit"
                @submit="createComment"
                @update="updateComment"
            ></VChatInput>
        </div>

        <VTaskPanelLayer v-model:visible="showAuthorLayer" header>
            <VUserSelector
                v-model="showAuthorLayerAuthors"
                actions-margin
                autofocus
                :cancel-button-title="t('cancel')"
                :clearable="false"
                :submit-button-title="t('apply')"
                :title="t('author')"
                @cancel="hideAuthorSelector"
                @submit="setAuthor"
            >
            </VUserSelector>
        </VTaskPanelLayer>

        <VTaskPanelLayer v-model:visible="showApproversLayer" header>
            <VUserSelector
                v-model="showApproversLayerApprovers"
                actions-margin
                autofocus
                :cancel-button-title="t('cancel')"
                deletable
                multiple
                :submit-button-title="t('send-for-approvement')"
                :title="t('approvers')"
                @cancel="hideApproverSelector"
                @submit="startApprovementProcess"
            >
            </VUserSelector>
        </VTaskPanelLayer>

        <VTaskPanelLayer v-model:visible="showCollaboratorsLayer" header>
            <VCollaboratorsList
                autofocus
                :collaborators="task.collaborators"
                :disabled="!canManageCollaborators || disabled"
                fluid
                :multiple="showCollaboratorsLayerContext!.multiple"
                :role="showCollaboratorsLayerContext!.role"
                :taskId="showCollaboratorsLayerContext!.taskId"
                title
                @added="addCollaboratorAsync(task, $event)"
                @edit-request="confirmToDraft"
                @removed="removeCollaboratorAsync(task, $event)"
                @replaced="replaceCollaboratorAsync(task, $event)"
            ></VCollaboratorsList>
        </VTaskPanelLayer>

        <VDropzone @drop="onFileDrop"></VDropzone>
    </div>
</template>

<script lang="ts">
// Svg
import DotsSvg from '@/assets/dots.svg';
import PlusSvg from '@/assets/plus.svg';
import TimesSvg from '@/assets/times.svg';
import NoticeSvg from '@/assets/notice.svg';

// Components
import VTaskStatus from './VTaskStatus.vue';
import VButton from './VButton.vue';
import VButtonDropdown from './VButtonDropdown.vue';
import VFileList from './VFileList.vue';
import VTaskCardInline from './VTaskCardInline.vue';
import VChatInput from './VChatInput.vue';
import VFeedStory from './VFeedStory.vue';
import VUsersList from './VUsersList.vue';
import VDatePicker from './VDatePicker.vue';
import VInlineLink from './VInlineLink.vue';
import VTaskPanelLayer from './VTaskPanelLayer.vue';
import VApprovementList from './VApprovementList.vue';
import VUserSelector from './VUserSelector.vue';
import VCollaboratorsList from './VCollaboratorsList.vue';
import VTextarea from './VTextarea.vue';
import VContentEditable from './VContentEditable.vue';
import VSelect from './VSelect.vue';
import VDropzone from './VDropzone.vue';

// Other
import ITask from '../core/Models/ITask';
import IUser from '@/core/Models/IUser';
import StoryType from '@/core/Values/StoryType';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import IAttachment from '@/core/Values/IAttachment';
import { fileExtension } from '@/utils/utils';
import { debounce } from 'debounce';
import IProject from '@/core/Models/IProject';
import IBoard from '@/core/Models/IBoard';
import TaskService, { QueryTaskRequest } from '@/core/Services/TaskService';
import TaskType from '@/core/Values/TaskType';
import IUploader from '@/core/Uploader/IUploader';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import IApiResult from '@/core/IApiResult';
import { defineComponent, markRaw, ref } from 'vue';
import { PropType } from 'vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import StoryViewMode, { viewModeOptions } from '@/core/Values/StoryViewMode';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { tryTranslate } from '../plugins/VueI18n';
import { useI18n } from 'vue-i18n';
import { useTaskActions, useTaskApi, useTaskChat, useTaskRights, validateTaskFields } from '@/mixins/TaskApi';
import { getTaskRouteLocation } from '../mixins/TaskApi';

interface CollaboratorsModalContext {
    role: CollaboratorRole;
    taskId: number;
    multiple: boolean;
}

interface ISelectOption {
    title: string;
    value: number;
}
type DebounceFunction = ((...params: unknown[]) => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    components: {
        DotsSvg,
        PlusSvg,
        TimesSvg,
        NoticeSvg,

        VTaskStatus,
        VButton,
        VButtonDropdown,
        VSelect,
        VFileList,
        VTaskPanelLayer,
        VTaskCardInline,
        VChatInput,
        VUsersList,
        VFeedStory,
        VDatePicker,
        VInlineLink,
        VUserSelector,
        VApprovementList,
        VCollaboratorsList,
        VTextarea,
        VDropzone,
        VContentEditable,
    },

    setup(props) {
        const task = ref(props.task);

        const { t } = useI18n();
        const route = useRoute();
        const router = useRouter();

        const api = useTaskApi();
        const chat = useTaskChat(task);
        const rights = useTaskRights(task);
        const actions = useTaskActions(
            task,
            (task: ITask, callback?: () => void): void => {
                formErrors.value = validateTaskFields(task);

                if (!formErrors.value) {
                    callback?.();
                    formErrorsDebounce.value?.flush();
                    formErrorsDebounce.value = null;
                    return;
                }

                if (!formErrorsDebounce.value) {
                    formErrorsDebounce.value = debounce(
                        () => (formErrors.value = formErrorsDebounce.value = null),
                        3000,
                    ); // 3s
                }

                formErrorsDebounce.value?.();
            },
            (task: ITask): void => {
                showApproversLayer.value = true;
                showApproversLayerApprovers.value = task.approvements?.length
                    ? task.approvements
                          ?.filter((approvement) => approvement.approver)
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .map((collaborator) => collaborator.approver!) ?? []
                    : task.collaborators
                          ?.filter(
                              (collaborator) => collaborator.user && collaborator.role === CollaboratorRole.Assignee,
                          )
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .map((collaborator) => collaborator.user!) ?? [];
            },
        );

        let fileQueue = ref([] as File[]);

        let formErrors = ref(null as Record<string, string> | null);
        let formErrorsDebounce = ref(null as DebounceFunction | null);

        let showApproversLayer = ref(false);
        let showApproversLayerApprovers = ref([] as IUser[]);

        return {
            t,
            route,
            router,

            ...api,
            ...chat,
            ...rights,
            ...actions,

            tryTranslate,

            fileQueue,
            formErrors,
            formErrorsDebounce,
            showApproversLayer,
            showApproversLayerApprovers,
        };
    },

    props: {
        task: { type: Object as PropType<ITask>, required: true },
        parent: { type: Object as PropType<ITask | null>, default: null },
        project: { type: Object as PropType<IProject | null>, default: null },
        subtasks: { type: Array as PropType<ITask[] | null>, default: null },
        uploader: { type: Object as PropType<IUploader<IApiResult<IObjectStoreModel>>>, required: true },
        board: { type: Object as PropType<IBoard>, default: null },
        canCreateSubTasks: { type: Boolean, default: false },
    },

    data: () => ({
        parentOptions: [] as ISelectOption[],

        viewModeOptions,

        showAuthorLayer: false,
        showAuthorLayerAuthors: [] as IUser[],

        showCollaboratorsLayer: false,
        showCollaboratorsLayerContext: null as CollaboratorsModalContext | null,

        fetchParentsOptionsDebounce: null as
            | (((search: string, loading: (isLoading: boolean) => void) => Promise<void>) & { clear(): void } & {
                  flush(): void;
              })
            | null,
    }),

    computed: {
        storyViewMode: Storages.Settings.computed(Settings.UI.StoryViewMode, StoryViewMode.All),

        isSubtask(): boolean {
            return this.task.type === TaskType.Subtask;
        },

        assignees(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Assignee);
        },

        contributors(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Contributor);
        },

        collaborators(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Collaborator);
        },

        attachments(): IAttachment[] {
            if (!this.task.stories) {
                return [];
            }

            return this.task.stories
                .filter((story) => story.type === StoryType.Attachment)
                .map(
                    (attachment): IAttachment => ({
                        id: attachment.id,
                        authorId: attachment.actorId,
                        fileName: attachment.fileName,
                        objectName: attachment.objectName,
                        extension: fileExtension(attachment.fileName),
                        downloadUri: attachment.downloadUri,
                    }),
                );
        },

        parentTitle(): string {
            return !this.parent || this.parent.type === TaskType.Goal ? this.t('goal', 1) : this.t('task', 1);
        },

        parentLink(): RouteLocationRaw | null {
            if (!this.parent) {
                return null;
            }

            return getTaskRouteLocation(this.parent);
        },
    },

    methods: {
        onFileDrop(files: File[]) {
            for (const file of files) {
                this.fileQueue.push(markRaw(file));
            }
        },

        startApprovementProcess(approvers: IUser[]): void {
            this.showApproversLayer = false;

            this.startApprovementProcessAsync(
                this.task,
                approvers.map((approver) => approver.id),
            );
        },

        hideCollaborators(): void {
            this.showCollaboratorsLayerContext = null;
        },

        hideAuthorSelector(): void {
            this.showAuthorLayer = false;
            this.showAuthorLayerAuthors = [];
        },

        showAuthorSelector(): void {
            if (!this.task.author) {
                return;
            }

            this.showAuthorLayer = true;
            this.showAuthorLayerAuthors = [this.task.author];
        },

        hideApproverSelector(): void {
            this.showApproversLayer = false;
            this.showApproversLayerApprovers = [];
        },

        showMangers(): void {
            this.showCollaboratorsWithRole(CollaboratorRole.Assignee, false);
        },

        showContributors(): void {
            this.showCollaboratorsWithRole(CollaboratorRole.Contributor);
        },

        showCollaborators(): void {
            this.showCollaboratorsWithRole(CollaboratorRole.Collaborator);
        },

        showCollaboratorsWithRole(role: CollaboratorRole, multiple = true): void {
            this.showCollaboratorsLayer = true;
            this.showCollaboratorsLayerContext = {
                taskId: this.task.id,
                role: role,
                multiple,
            };
        },

        getOptionValue(option: { value: number }): number {
            return option.value;
        },

        getCollaboratorsWithRole(role: CollaboratorRole): IUser[] {
            return (
                this.task.collaborators?.reduce((result, collaborator) => {
                    if (collaborator.user && collaborator.role === role) {
                        result.push(collaborator.user);
                    }

                    return result;
                }, [] as IUser[]) ?? []
            );
        },

        setAuthor(authors: IUser[]): void {
            if (authors.length <= 0) {
                return;
            }

            this.showAuthorLayer = false;
            this.changeAuthorAsync(this.task, authors[0].id);
        },

        async fetchParentsAsync(search = ''): Promise<void> {
            if (this.task.type === TaskType.Goal) {
                return;
            }

            let query: QueryTaskRequest = {
                page: 1,
                perPage: 10,
                search,
                whereTitleNot: [''],
                whereType: this.task.type == TaskType.Task ? [TaskType.Goal] : [TaskType.Subtask],
            };

            const parents = await TaskService.queryAsync(query);

            this.parentOptions = parents.map((parent) => ({
                value: parent.id,
                title: parent.title,
            }));
        },

        fetchParentsOptions(search: string, loading: (isLoading: boolean) => void): void {
            if (!this.fetchParentsOptionsDebounce) {
                this.fetchParentsOptionsDebounce = debounce(
                    async (search: string, loading: (isLoading: boolean) => void) => {
                        loading(true);
                        try {
                            await this.fetchParentsAsync(search);
                        } finally {
                            loading(false);
                            this.fetchParentsOptionsDebounce = null;
                        }
                    },
                    500,
                );
            }

            this.fetchParentsOptionsDebounce(search, loading);
        },

        fetchParentsOptionsIfNotExistsOrStartEdit(): void {
            if (!this.editable) {
                return this.confirmToDraft();
            }

            if (!this.parentOptions.length) {
                this.fetchParentsAsync();
            }
        },

        applyRouteAction(): void {
            const action = this.route.query.action;

            switch (action) {
                case 'validate':
                    this.validate(this.task);
                    break;
                case 'select-approvers':
                    this.showApproverSelector(this.task);
                    break;
            }

            if (action) {
                this.router.replace({ query: { ...this.route.query, action: undefined } });
            }
        },
    },

    watch: {
        'task'() {
            const taskPanel = document.querySelector('.task-panel__scroll') as HTMLElement;
            if (taskPanel) {
                taskPanel.scrollTop = 0;
            }
        },
        '$route.query.action': 'applyRouteAction',
    },

    mounted() {
        this.applyRouteAction();
    },
});
</script>

<style lang="scss">
.task-panel {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: var(--background-color);
    --background-color: inherit;

    &__scroll {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-grow: 1;
        overflow: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__row {
        width: 100%;
        padding: 0 0.5rem;

        @media (min-width: #{$breakpoint-tablet + 1}) {
            padding: 0 1rem;
        }

        &--flex {
            display: flex;
            flex-direction: column;
        }

        &--first {
            padding-top: 1rem;
        }

        &--center {
            display: flex;
            align-items: center;
        }

        &--padding {
            padding: 0 1rem;

            @media (min-width: #{$breakpoint-tablet + 1}) {
                padding: 0 2rem;
            }
        }

        &--separate {
            margin-top: 1.25rem;
        }

        &--secondary {
            background-color: var(--background-color);
            --background-color: var(--background-secondary);
        }

        &--close-button-space {
            position: relative;
            padding-right: 3.5rem;

            color: var(--text-black-primary);

            @media (min-width: #{$breakpoint-tablet + 1}) {
                padding-right: 4rem;
            }
        }
    }

    &__alert {
        margin: 0;
        padding: 1rem 0;

        @include h4();
        color: var(--color-negative);
    }

    &__alert-icon {
        width: 1.75rem;
        height: 1.75rem;
        margin-right: 0.25rem;

        color: var(--color-negative);
    }

    &__label {
        @include h7(false);
        @include overflow-ellipsis();

        color: var(--text-black-secondary);

        &--separate {
            margin-bottom: 0.25rem;
        }

        &--separate-x2 {
            margin-bottom: 0.5rem;
        }
    }

    &__chat {
        width: 100%;
        flex-shrink: 0;
        max-height: 24rem;
        overflow: hidden;

        border-top: 0.0625rem solid var(--background-tertiary);
        box-shadow: 0 0 0.6875rem 0 rgba(0, 0, 0, 0.07);
        background: var(--background-color);
        --background-color: var(--background-primary);
    }

    &__stories {
        margin: 0 -2rem 0 -2rem;
    }

    &__button {
        &--close {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            padding: 0.5rem;

            color: var(--text-black-primary);

            @media (min-width: #{$breakpoint-tablet + 1}) {
                right: 1rem;
            }
        }
    }

    &__header {
        margin: 1.25rem 0;

        &--title {
            @include h3();

            color: var(--text-black-primary);
        }

        &--selector {
            display: flex;

            & .v-select {
                height: 2.625rem;
            }

            & .vs__selected {
                @include h3();
            }
        }

        &--with-button {
            position: relative;
        }
    }

    &__header-button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.125rem 0.5rem;

        @include button-secondary(false);

        line-height: 1.5rem;
        color: var(--text-black-primary);
    }

    &__subtask {
        position: relative;
        padding: 0.25rem 2rem 0.25rem 1.625rem;
        margin-bottom: -0.0625rem;

        &:hover {
            z-index: 1;
        }

        &:first {
            margin-top: 0;
        }
    }

    &__subtasks {
        margin: 0 -2rem 0 -2rem;
    }
}
</style>
